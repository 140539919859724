<template>
  <v-navigation-drawer
    class="bg-color"
    order="1"
    permanent
    sticky
    :floating="rail"
    width="350"
    :rail="rail"
  >
    <v-list v-if="!rail && !loading" class="bg-color">
      <v-list-item>
        <div class="text-orange text-h6 font-weight-bold">Panduan</div>
        <div class="text-primary text-h6 font-weight-bold">
          {{ title }}
        </div>
      </v-list-item>
      <v-list-item
        v-for="(item, i) in navigation"
        :key="i"
        :value="item"
        color="primary"
        @click="scroll(`${i + 1}`)"
      >
        <v-list-item-title> {{ i + 1 }}. {{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list>
      <v-list-item>
        <div class="d-flex justify-end">
          <v-btn
            @click="rail = !rail"
            :icon="
              !rail ? 'mdi-arrow-collapse-left' : 'mdi-arrow-collapse-right'
            "
            variant="text"
            size="small"
          >
          </v-btn>
        </div>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-container fluid>
    <v-row v-if="!loading">
      <v-col>
        <div class="d-flex flex-column">
          <div class="text-h5">{{ title }}</div>

          <div
            :ref="`${index + 1}`"
            class="my-3"
            :id="index + 1"
            v-for="(content, index) in contents"
            :key="index"
          >
            <div class="font-weight-bold">
              {{ index + 1 }}. {{ content.footercontent_title }}
            </div>
            <div class="mb-3">
              <div class="px-3">
                <div>
                  {{ content.footercontent_description }}
                </div>
                <v-img class="my-2" :src="content.footercontent_image"> </v-img>
              </div>

              <div v-if="content.footercontent_child?.length" class="px-3">
                <template
                  v-for="(child, childIndex) in content.footercontent_child"
                  :key="childIndex"
                >
                  <div>
                    {{ child.footercontent_title }}
                  </div>
                  <div>
                    {{ child.footercontent_description }}
                  </div>
                  <v-img class="my-2" :src="child.footercontent_image"> </v-img>
                </template>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <v-row>
    <v-col class="d-none d-sm-block" cols="3"></v-col>
    <v-col>
      <p class="text-h6 font-weight-bold text-center">
        {{ $t("help-center.survey.title") }}
      </p>
      <v-row justify="center" class="mb-5">
        <v-col cols="auto">
          <v-chip-group :multiple="false" v-model="isHelped">
            <v-chip
              label
              color="primary-container"
              class="text-none text-primary mx-3"
              selected-class="bg-primary"
              value="ya"
              @click="sendHelped(true)"
            >
              {{ $t("help-center.survey.options.helped") }}</v-chip
            >

            <v-chip
              label
              color="primary-container"
              class="text-none text-primary mx-3"
              selected-class="bg-primary"
              value="tidak"
            >
              {{ $t("help-center.survey.options.not-helped") }}</v-chip
            >
          </v-chip-group>
        </v-col>
      </v-row>

      <div v-if="isHelped == 'tidak'">
        <div class="text-bold">
          {{ $t("help-center.survey.title") }}
        </div>

        <v-radio-group v-model="helped">
          <v-radio
            v-for="(text, index) in notHelped"
            :key="index"
            :label="text"
            :value="text"
          ></v-radio>
          <v-radio
            value="other"
            :label="$t('help-center.survey.sub-option.options[2]')"
          >
          </v-radio>
        </v-radio-group>

        <v-textarea
          v-if="helped == 'other'"
          v-model="explanation"
          variant="outlined"
          :placeholder="$t('help-center.survey.sub-option.other.placeholder')"
        >
        </v-textarea>

        <div class="d-flex">
          <v-btn
            class="bg-primary"
            @click="sendHelped(false)"
            :loading="loadingSend"
          >
            Kirim
          </v-btn>
        </div>
      </div>
    </v-col>
    <v-col class="d-none d-sm-block" cols="3"></v-col>
  </v-row>

  <v-dialog v-model="surveyAccepted" width="auto">
    <div class="bg-white pa-3 rounded-lg d-flex text-primary">
      <v-icon class="me-2">mdi-check-circle</v-icon>
      <div class="font-weight-bold">
        {{ $t("help-center.survey.accepted") }}
      </div>
    </div>
  </v-dialog>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn
        color="pink"
        rounded="pill"
        variant="text"
        @click="snackbar.status = false"
      >
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import scroll from "@/utils/scroller";

export default {
  created() {
    const getInfo = this.$route.meta?.name;
    this.info = getInfo;
  },

  data() {
    return {
      rail: false,
      title: null,
      navigation: [],
      contents: [],
      info: null,
      footerInfoId: null,
      loading: false,
      surveyAccepted: null,
      notHelped: [
        this.$t("help-center.survey.sub-option.options[0]"),
        this.$t("help-center.survey.sub-option.options[1]"),
      ],
      helped: "",
      explanation: "",
      isHelped: null,
      loadingSend: false,
      snackbar: {
        status: false,
        text: "",
      },
      errorFetch: false,
    };
  },

  methods: {
    scroll(id) {
      this.$nextTick(() => {
        scroll(id);
      });
    },

    getData() {
      this.loading = true;
      this.axios
        .get(`info/footer/get`, {
          params: {
            info: this.info,
          },
        })
        .then((response) => {
          const result = response.data.response[0];
          this.title = result.footerinfo_name;
          this.footerInfoId = result.footerinfo_id;
          this.navigation = result.footer_content
            .filter((content) => content.footercontent_title !== null)
            .map((content) => content.footercontent_title);

          this.contents = result.footer_content
            .map((content) => {
              if (content.footercontent_parent !== null) {
                const parentIndex = result.footer_content.findIndex(
                  (parent) =>
                    parent.footercontent_id === content.footercontent_parent
                );
                if (
                  result.footer_content[parentIndex].footercontent_child ===
                  undefined
                ) {
                  result.footer_content[parentIndex].footercontent_child = [];
                }
                result.footer_content[parentIndex].footercontent_child.push(
                  content
                );
                return null;
              } else {
                return content;
              }
            })
            .filter((content) => content !== null);
        })
        .catch((error) => {
          console.log(error);
          this.errorFetch = true;
        })
        .finally(() => (this.loading = false));
    },

    sendHelped(isHelped) {
      this.loadingSend = true;
      this.axios
        .post(`info/footer/${this.footerInfoId}/survey/send`, {
          is_helping: isHelped,
          details: this.helped == "other" ? this.explanation : this.helped,
        })
        .then(() => {
          this.surveyAccepted = true;
        })
        .catch((error) => {
          if (error?.response?.data.message) {
            this.snackbar.text = error?.response?.data.message;
            this.snackbar.status = true;
          }
        })
        .finally(() => (this.loadingSend = false));
    },
  },

  watch: {
    info: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getData();
        }
      },
    },

    surveyAccepted(newValue) {
      if (newValue == false) {
        this.$router.go();
      }
    },
  },
};
</script>