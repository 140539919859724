<template>
  <v-container v-if="!errorFetch" fluid>
    <v-row>
      <v-col>
        <p class="text-h5 font-weight-bold text-center">
          {{ $t("help-center.title") }}
        </p>
      </v-col>
    </v-row>
    <v-row class="bg-primary-container py-5">
      <v-col class="d-flex justify-center">
        <v-text-field
          :style="{ 'max-width': '320px' }"
          class="mx-2"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          variant="solo"
          rounded="lg"
          hide-details
          single-line
          type="search"
          @keydown.enter.prevent="step = 'search'"
          :label="$t('help-center.search.placeholder')"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="step == 'all'">
      <v-col>
        <div class="text-base text-center font-weight-bold text-h5 my-2">
          {{ $t("help-center.select-role.title[0]") }}
          <span class="font-italic">
            {{ $t("help-center.select-role.title[1]") }}</span
          >
          {{ $t("help-center.select-role.title[2]") }}
        </div>
        <div class="d-flex justify-center">
          <v-chip-group
            v-model="role"
            mandatory
            class="text-tertiary justify-center"
            selected-class="text-white bg-tertiary"
          >
            <v-chip
              variant="outlined"
              rounded="lg"
              v-for="category in faqCategories"
              :key="category"
              :value="category.faquser_id"
              class="pa-16 text-h5"
              >{{ category.faq_user }}</v-chip
            >
          </v-chip-group>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters justify="space-between" class="my-2">
      <v-col class="d-none d-md-block" v-if="step == 'all'" cols="3">
        <v-card class="sticky" width="200">
          <v-card-title class="text-secondary-60">FAQ</v-card-title>
          <v-list v-if="rail" variant="text">
            <v-list-item
              @click="scroll(item.faqcategory_id)"
              v-for="item in getList"
              :key="item.faqcategory_id"
            >
              <v-list-item-title>
                {{ item.faqcategory_name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-card-actions class="justify-end">
            <v-btn
              @click="rail = !rail"
              :icon="
                rail ? 'mdi-arrow-collapse-left' : 'mdi-arrow-collapse-right'
              "
              size="small"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col>
        <v-window :touch="false" v-model="step">
          <v-window-item value="all">
            <div class="px-2">
              <template v-for="item in getDrawer" :key="item">
                <p
                  :ref="item.faqcategory_name"
                  :id="item.faqcategory_id"
                  class="text-base font-weight-bold mb-2 pl-4"
                >
                  {{ item.faqcategory_name }}
                </p>
                <v-expansion-panels multiple class="mb-5">
                  <v-expansion-panel
                    v-for="expand in item.FAQs"
                    :key="expand"
                    class="mb-4"
                  >
                    <v-expansion-panel-title class="font-weight-bold">
                      {{ expand.faq_question }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      {{ expand.faq_answer }}
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </div>
          </v-window-item>

          <v-window-item value="search">
            <v-row class="mt-5">
              <v-col cols="3"></v-col>
              <v-col>
                <div class="d-flex align-center">
                  <v-btn
                    variant="tonal"
                    color="primary"
                    @click="step = 'all'"
                    prepend-icon="mdi-arrow-left"
                    class="me-4"
                    >{{ $t("help-center.button.back") }}</v-btn
                  >
                  <span class="font-weight-medium text-grey">
                    {{
                      $t("help-center.search.result", {
                        count: findFAQ.count,
                        keyword: search,
                      })
                    }}
                  </span>
                </div>
              </v-col>

              <v-col cols="3"></v-col>
            </v-row>
            <v-row>
              <v-col class="d-none d-md-block" cols="3"></v-col>
              <v-col>
                <div class="px-2">
                  <template v-for="item in findFAQ.data" :key="item">
                    <p
                      :ref="item.faqcategory_name"
                      class="text-base font-weight-bold mb-2 pl-4"
                    >
                      {{ item.faqcategory_name }}
                    </p>
                    <v-expansion-panels multiple class="mb-5">
                      <v-expansion-panel
                        v-for="expand in item.FAQs"
                        :key="expand"
                        class="mb-4"
                      >
                        <v-expansion-panel-title class="font-weight-bold">
                          {{ expand.faq_question }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                          {{ expand.faq_answer }}
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template>
                </div>
              </v-col>
              <v-col class="d-none d-md-block" cols="3"></v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-col>

      <v-col class="d-none d-md-block" v-if="step == 'all'" cols="3"> </v-col>
    </v-row>

    <v-row>
      <v-col class="d-none d-sm-block" cols="3"></v-col>
      <v-col>
        <p class="text-h6 font-weight-bold text-center">
          {{ $t("help-center.survey.title") }}
        </p>
        <v-row justify="center" class="mb-5">
          <v-col cols="auto">
            <v-chip-group :multiple="false" v-model="isHelped">
              <v-chip
                label
                color="primary-container"
                class="text-none text-primary mx-3"
                selected-class="bg-primary"
                value="ya"
                @click="sendHelped(true)"
              >
                {{ $t("help-center.survey.options.helped") }}</v-chip
              >

              <v-chip
                label
                color="primary-container"
                class="text-none text-primary mx-3"
                selected-class="bg-primary"
                value="tidak"
              >
                {{ $t("help-center.survey.options.not-helped") }}</v-chip
              >
            </v-chip-group>
          </v-col>
        </v-row>

        <div v-if="isHelped == 'tidak'">
          <div class="text-bold">
            {{ $t("help-center.survey.title") }}
          </div>

          <v-radio-group v-model="helped">
            <v-radio
              v-for="(text, index) in notHelped"
              :key="index"
              :label="text"
              :value="text"
            ></v-radio>
            <v-radio
              value="other"
              :label="$t('help-center.survey.sub-option.options[2]')"
            >
            </v-radio>
          </v-radio-group>

          <v-textarea
            v-if="helped == 'other'"
            v-model="explanation"
            variant="outlined"
            :placeholder="$t('help-center.survey.sub-option.other.placeholder')"
          >
          </v-textarea>

          <div class="d-flex">
            <v-btn
              class="bg-primary"
              @click="sendHelped(false)"
              :loading="loadingSend"
            >
              Kirim
            </v-btn>
          </div>
        </div>

        <p class="text-base text-h6 font-weight-bold">
          {{ $t("help-center.more-help.title") }}
        </p>
        <p class="text-base font-weight-medium">
          {{ $t("help-center.more-help.subtitle") }}
        </p>
        <v-btn
          :href="`mailto:${contact.EMAIL}`"
          prepend-icon="mdi-email"
          variant="text"
          class="text-none pl-1"
          ><template v-slot:prepend>
            <v-icon color="secondary-60"></v-icon>
          </template>
          {{ contact.EMAIL }}</v-btn
        >
        <!-- <v-btn prepend-icon="mdi-whatsapp" variant="text" class="text-none"
          ><template v-slot:prepend>
            <v-icon color="secondary-60"></v-icon>
          </template>
          0811-453-678</v-btn
        > -->
      </v-col>
      <v-col class="d-none d-sm-block" cols="3"></v-col>
    </v-row>
  </v-container>

  <div v-else class="h-100 d-flex justify-center align-center">
    <error
      type="server_error"
      width="400"
      text="Ini bukan salahmu tetapi memang sedang terjadi gangguan. Silahkan muat ulang atau hubungi kami di pusat bantuan."
      title="Maaf, Ada Kesalahan Server"
      button="muat ulang"
      @fetch="
        () => {
          this.$router.go();
        }
      "
    />
  </div>

  <v-dialog v-model="surveyAccepted" width="auto">
    <div class="bg-white pa-3 rounded-lg d-flex text-primary">
      <v-icon class="me-2">mdi-check-circle</v-icon>
      <div class="font-weight-bold">
        {{ $t("help-center.survey.accepted") }}
      </div>
    </div>
  </v-dialog>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn
        color="pink"
        rounded="pill"
        variant="text"
        @click="snackbar.status = false"
      >
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import contact from "@/config/contact";
import scroll from "@/utils/scroller";
import Error from "@/components/error/ErrorComponent.vue";

export default {
  data() {
    return {
      contact: contact,
      explanation: "",
      surveyAccepted: null,
      step: "all",
      search: "",
      listFaq: [],
      faqCategories: [],
      selectedRole: 1,
      chips: ["Buyer", "Seller"],
      role: 1,
      drawer: true,
      rail: true,
      notHelped: [
        this.$t("help-center.survey.sub-option.options[0]"),
        this.$t("help-center.survey.sub-option.options[1]"),
      ],
      helped: "",
      isHelped: null,
      loadingSend: false,
      snackbar: {
        status: false,
        text: "",
      },
      errorFetch: false,
    };
  },

  components: {
    Error,
  },

  created() {
    this.axios
      .get("/info/faq/cat/get")
      .then((response) => {
        const data = response.data.response;
        this.faqCategories = data;
      })
      .catch(() => {
        this.errorFetch = true;
      });
    this.axios
      .get("/info/faq/get")
      .then((response) => {
        const data = response.data.response;
        this.listFaq = data;
      })
      .catch(() => {
        this.errorFetch = true;
      });
  },

  computed: {
    findFAQ() {
      const searchFAQ = {
        data: [],
        count: 0,
      };
      const query = this.search.toLocaleLowerCase();
      for (const user of this.listFaq) {
        for (const category of user.faq_categories) {
          const res = [];
          for (const faq of category.FAQs) {
            const question = faq.faq_question.toLowerCase();
            if (question.includes(query)) {
              res.push(faq);
              searchFAQ.count++;
            }
          }
          if (res.length > 0) {
            searchFAQ.data.push({
              faqcategory_id: category.faqcategory_id,
              faqcategory_name: category.faqcategory_name,
              FAQs: res,
            });
          }
        }
      }

      return searchFAQ;
    },
    getList() {
      const faqCategory = this.faqCategories.find(
        (category) => category.faquser_id == this.role
      );

      if (faqCategory) {
        return faqCategory.faq_categories;
      } else {
        return [];
      }
    },
    getDrawer() {
      const faq = this.listFaq.find((faq) => faq.faquser_id == this.role);

      if (faq) {
        return faq.faq_categories;
      } else {
        return [];
      }
    },
  },

  methods: {
    sendHelped(isHelped) {
      this.loadingSend = true;
      this.axios
        .post("/info/faq/survey/send", {
          faqsurvey_ishelping: isHelped,
          details: this.helped == "other" ? this.explanation : this.helped,
        })
        .then(() => {
          this.surveyAccepted = true;
        })
        .catch((error) => {
          if (error?.response?.data.message) {
            this.snackbar.text = error?.response?.data.message;
            this.snackbar.status = true;
          }
        })
        .finally(() => {
          this.loadingSend = false;
        });
    },
    scroll(id) {
      this.$nextTick(() => {
        scroll(id);
      });
    },
  },
  watch: {
    surveyAccepted(newValue) {
      if (newValue == false) {
        this.$router.go();
      }
    },
  },
};
</script>

<style scoped>
</style>