<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p class="text-h6 font-weight-bold">Biaya</p>
        <p class="text-body-1 font-weight-bold bg-secondary-container py-3 pl-2">
          Pembeli
        </p>
        <v-row justify="start" class="mb-4">
          <v-col cols="5">
            <p class="font-weight-bold text-body-1">Biaya Platform</p>
          </v-col>
          <v-col cols="5">
            <p class="text-body-2 font-weight-medium">3%</p>
            <p class="text-body-2 font-weight-medium">*Per transaksi</p>
          </v-col>
        </v-row>
        <v-row justify="start" class="mb-4">
          <v-col cols="5">
            <p class="font-weight-bold text-body-1">Biaya Layanan</p>
          </v-col>
          <v-col cols="5">
            <p class="text-body-2 font-weight-medium">
              *Sesuai metode pembayaran yang dipilih
            </p>
          </v-col>
        </v-row>

        <p class="text-body-1 font-weight-bold bg-primary-container py-3 pl-2">
          Penjual
        </p>
        <v-row justify="start" class="mb-4">
          <v-col cols="5">
            <p class="font-weight-bold text-body-1">Biaya Disbursement</p>
          </v-col>
          <v-col cols="5">
            <p class="text-body-2 font-weight-medium">Rp 2.500 (BI-Fast)</p>
          </v-col>
          <v-col cols="5">
            <p class="font-weight-bold text-body-1">
              Layanan Tambahan Banner Halaman Depan e-tix
            </p>
          </v-col>
          <v-col cols="5">
            <p class="text-body-2 font-weight-medium">Rp. 100.000</p>
            <p class="text-body-2 font-weight-medium">*Per 7 hari</p>
          </v-col>
        </v-row>

        <p class="text-body-1 font-weight-bold bg-primary-container py-3 pl-2 mb-2">
          Layanan Tambahan Penjual
        </p>
        <v-row>
          <v-col cols="6"> </v-col>
          <v-col>
            <p class="text-body-1 font-weight-bold text-center">
              Layanan Aplikasi Validasi Tiket
            </p>
          </v-col>

          <v-col>
            <p class="text-body-1 font-weight-bold text-center">
              Layanan Aplikasi Terminal
            </p>
          </v-col>
        </v-row>

        <v-row>
          <template v-for="(item, i) in this.package" :key="i">
            <v-col cols="6">
              <p class="text-body-1 font-weight-bold">{{ item.title }}</p>
            </v-col>
            <v-col>
              <v-sheet width="auto" class="d-flex justify-center bg-color">
                <v-icon :color="item.terminal ? 'tertiary' : 'error'" :icon="item.terminal ? 'mdi-check-circle' : 'mdi-close-circle'
                  "></v-icon>
              </v-sheet>
            </v-col>

            <v-col>
              <v-sheet width="auto" class="d-flex justify-center bg-color">
                <v-icon :color="item.operator ? 'tertiary' : 'error'" :icon="item.operator ? 'mdi-check-circle' : 'mdi-close-circle'
                  "></v-icon>
              </v-sheet>
            </v-col>
          </template>
        </v-row>

        <v-row>
          <v-col cols="6"> </v-col>
          <v-col cols="6"> <v-divider :thickness="3"></v-divider> </v-col>
        </v-row>

        <v-row>
          <v-col cols="6"> </v-col>
          <v-col>
            <p class="text-body-2 font-weight-bold text-center">
              Rp 150.000 /bulan
            </p>
          </v-col>

          <v-col>
            <p class="text-body-2 font-weight-bold text-center">
              Rp 350.000 /bulan
            </p>
          </v-col>
        </v-row>

        <p class="text-body-1 font-italic font-weight-bold text-primary">
          *Skema pembayaran paket layanan tambahan berlaku untuk 5 user
        </p>
      </v-col>
    </v-row>

    <v-sheet width="auto" class="mt-6 py-10 px-16 text-center" color="tertiary-container-dark">
      <p class="text-h5 font-weight-bold mb-2">Coba e-tix Sekarang!</p>
      <p class="text-subtitle-1 mb-2">
        Rasakan kemudahan dan kenyamanan membeli tiket hanya di e-tix
      </p>
      <v-btn color="white" @click="dialog.daftar.show = true" class="text-none text-primary">Daftar</v-btn>
    </v-sheet>

    <v-row class="ma-4">
      <v-col cols="12" xxl="3" xl="3" lg="3" md="3" sm="3">
        <p class="text-secondary-60 text-body-1 font-weight-bold">FAQ</p>
        <p class="text-base text-body-2">
          Hal-hal yang perlu kamu ketahui tentang biaya e-tix
        </p>
      </v-col>

      <v-col cols="12" xxl="9" xl="9" lg="9" md="9" sm="9">
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="item in faq" :key="item" class="bg-neutral-95 my-4">
            <v-expansion-panel-title class="text-neutral-20 font-weight-bold">
              {{ item.faq_question }}
            </v-expansion-panel-title>
            <v-expansion-panel-text class="text-neutral-50">
              {{ item.faq_answer }}
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <p class="text-bg-primary-container text-body-1 font-weight-bold text-center mt-4">
      Ada pertanyaan?
    </p>
    <p class="text-body-1 text-bg-primary-container font-weight-reguler text-center">
      Hubungi kami di
      <v-btn :href="`mailto:${contact.EMAIL}`" prepend-icon="mdi-email" variant="text" class="text-none pl-1"><template
          v-slot:prepend>
          <v-icon color="secondary-60"></v-icon>
        </template>
        {{ contact.EMAIL }}</v-btn>
    </p>
  </v-container>

  <v-dialog width="400" v-model="dialog.daftar.show" class="text-center pa-5">
    <v-card>
      <v-card-item>
        <v-card-title class="text-wrap">
          Oops, Kamu Masih Belum Berada di Akun Seller
        </v-card-title>
      </v-card-item>

      <v-card-text>
        Masuk dulu atau buat akun jika belum punya ya!
      </v-card-text>

      <v-card-actions>
        <v-btn variant="tonal" class="bg-primary" :href="url.web_seller + 'signin'" block>Masuk</v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn variant="tonal" color="primary" :href="url.web_seller + 'signup'" block>Daftar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import contact from "@/config/contact";

export default {
  data() {
    return {
      url: {
        web_seller: process.env.VUE_APP_WEB_SELLER_URL,
      },
      contact: contact,
      dialog: {
        daftar: {
          show: false,
        },
      },
      selected: null,
      package: [
        {
          title: "Akses Web Marketplace",
          terminal: true,
          operator: true,
        },

        {
          title: "EDC Terminal/Mobile App",
          terminal: true,
          operator: true,
        },

        {
          title: "Debit, CC, QRIS, e-Money Card",
          terminal: false,
          operator: true,
        },
        {
          title: "EDC Terminal/Tablet/POS/Kiosk",
          terminal: false,
          operator: false,
        },
        {
          title: "EDC Terminal atau Kiosk",
          terminal: false,
          operator: true,
        },
        {
          title: "Gratis Pemasangan Pertama",
          terminal: false,
          operator: true,
        },
        {
          title: "Bebas Biaya Ongkos Kirim",
          terminal: false,
          operator: true,
        },
      ],
      faq: [],
    };
  },
  created() {
    this.axios
      .get("/info/faq/get", {
        params: {
          user: "seller",
          category: 8,
        },
      })
      .then((response) => {
        const data = response.data.response;
        this.faq = data[0]?.faq_categories[0]?.FAQs;
      });
  },
};
</script>

<style scoped>
.row-custom {
  position: relative;
}

.card-custom {
  z-index: 1 !important;
}

.button-custom {
  position: absolute;
  bottom: -2rem;
  z-index: 1;
}
</style>