<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10">
        <v-alert
          border="start"
          border-color="tertiary"
          class="text-h6 font-weight-bold bg-color"
          >{{ $t("contact-us.title") }}</v-alert
        >
        <p class="text-body-1">
          {{ $t("contact-us.description") }}
        </p>

        <v-card elevation="4" class="mt-4">
          <v-card-title
            class="text-center text-decoration-underline text-primary-50 text-h6 my-3"
            >{{ $t("contact-us.content.title") }}</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" xxl="6" xl="6" lg="6" md="6">
                <v-form ref="emailMessage">
                  <v-text-field
                    :label="$t('contact-us.content.form.name.label')"
                    v-model="input.name"
                    :rules="rules.name"
                    :placeholder="
                      $t('contact-us.content.form.name.placeholder')
                    "
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    :label="$t('contact-us.content.form.email.label')"
                    v-model="input.email"
                    :rules="rules.email"
                    :placeholder="
                      $t('contact-us.content.form.email.placeholder')
                    "
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    :label="$t('contact-us.content.form.subject.label')"
                    v-model="input.subject"
                    :rules="rules.subject"
                    :placeholder="
                      $t('contact-us.content.form.subject.placeholder')
                    "
                    variant="outlined"
                  ></v-text-field>
                  <v-textarea
                    :label="$t('contact-us.content.form.message.label')"
                    v-model="input.message"
                    :rules="rules.message"
                    :placeholder="
                      $t('contact-us.content.form.message.placeholder')
                    "
                    variant="outlined"
                  ></v-textarea>
                  <v-btn
                    class="text-none mb-2"
                    @click="sendEmail"
                    color="primary"
                    block
                    :loading="loadingBtn"
                    >{{ $t("contact-us.button.send") }}</v-btn
                  >
                </v-form>
              </v-col>
              <v-col cols="12" xxl="6" xl="6" lg="6" md="6">
                <div>
                  <v-icon class="mr-2" icon="mdi-map-marker-outline"></v-icon>
                  <span class="text-body-1">
                    Graha Pena 8th Floor Unit #802 Jl. Kebayoran Lama No.12
                    Kebayoran Lama, Jakarta Selatan 12210
                  </span>
                </div>

                <div class="d-flex align-center">
                  <v-icon class="mr-2" icon="mdi-phone-outline"></v-icon>
                  <a href="tel:+622153344154" class="text-body-1"
                    >+62 21 533 44154</a
                  >
                </div>

                <div class="d-flex align-center">
                  <v-icon class="mr-2" icon="mdi-email-outline"></v-icon>
                  <a class="text-body-1" :href="'mailto:' + contact.EMAIL">{{
                    contact.EMAIL
                  }}</a>
                </div>
                <v-spacer></v-spacer>

                <v-sheet
                  class="my-2"
                  :height="240"
                  width="auto"
                  rounded
                  id="map"
                ></v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <div class="d-flex justify-space-between">
    <div>
      <v-img
        @contextmenu.prevent
        width="150"
        height="150"
        src="@/assets/ilustration_contact_01.png"
        lazy-src="@/assets/ilustration_contact_01.png"
      ></v-img>
    </div>

    <div>
      <v-img
        @contextmenu.prevent
        height="150"
        width="150"
        src="@/assets/ilustration_contact_02.svg"
        lazy-src="@/assets/ilustration_contact_02.svg"
      ></v-img>
    </div>
  </div>

  <!-- <v-row justify="space-between">
    <v-col cols="auto" class="me-auto">
      <v-img
        @contextmenu.prevent
        width="150"
        src="@/assets/ilustration_contact_01.png"
        lazy-src="@/assets/ilustration_contact_01.png"
      ></v-img>
    </v-col>
    <v-col cols="auto">
      <v-img
        @contextmenu.prevent
        width="150"
        src="@/assets/ilustration_contact_02.svg"
        lazy-src="@/assets/ilustration_contact_02.svg"
      ></v-img>
    </v-col>
  </v-row> -->

  <v-snackbar snackbar color="white" v-model="snackbar.show" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn @click="snackbar.show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import mapboxgl from "mapbox-gl";
import isEmail from "validator/lib/isEmail";
import contact from "@/config/contact";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  data() {
    return {
      contact: contact,
      input: {
        name: null,
        email: null,
        subject: null,
        message: null,
      },
      rules: {
        name: [(v) => !!v || this.$t("contact-us.content.form.name.rules[0]")],
        email: [
          (v) => !!v || this.$t("contact-us.content.form.email.rules[0]"),
          (v) =>
            isEmail(v) || this.$t("contact-us.content.form.email.rules[1]"),
        ],
        subject: [
          (v) => !!v || this.$t("contact-us.content.form.subject.rules[0]"),
        ],
        message: [
          (v) => !!v || this.$t("contact-us.content.form.message.rules[0]"),
        ],
      },
      access_token:
        "pk.eyJ1Ijoia2lrYXp1cnUiLCJhIjoiY2xmMjVyNDY0MGY5bTNxcDhkZ2dteXNsbCJ9.pLScRnkDfCI5eOizofKi-Q",
      center: [106.77800633680918, -6.2153911058907685],
      loadingBtn: false,
      snackbar: {
        show: false,
        text: "",
      },
    };
  },

  mounted() {
    this.createMap();
  },

  methods: {
    async sendEmail() {
      const { valid } = await this.$refs.emailMessage.validate();
      if (valid) {
        this.loadingBtn = true;
        this.axios
          .post("/callback/mailer/contactus", this.input)
          .then(() => {
            this.snackbar.text = this.$t(
              "contact-us.content.send-email.success"
            );
            this.snackbar.show = true;
          })
          .catch(() => {
            this.snackbar.text = this.$t(
              "contact-us.content.send-email.service-error"
            );
            this.snackbar.show = true;
          })
          .finally(() => {
            this.loadingBtn = false;
          });
      }
    },
    async createMap() {
      try {
        mapboxgl.accessToken = this.access_token;

        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v12",
          center: this.center,
          zoom: 13,
          cooperativeGestures: true
        });

        map.on("load", () => {
          const marker = new mapboxgl.Marker();
          marker
            .setLngLat(this.center)
            .setPopup(
              new mapboxgl.Popup({ offset: 10 }).setHTML(
                `<h3>PT. Softorb Technology Indonesia</h3>`
              )
            )
            .addTo(map);
        });
      } catch (err) {
        console.log("map error", err);
      }
    },
  },
};
</script>

<style>
.bg-color {
  background-color: #f8f8f8;
}
</style>